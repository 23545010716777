

import { useEffect, useMemo, useState } from "react";
import WrapperLayout from "components/Layout";
import Loader from "./Loader";
import {
  COUNTRYREPRESENTATION,
  MOSTFREQUENTMARKS,
  MOSTMISSINGCLASSES,
  MOSTMISSINGTRADEMARKSCOUNTRIES,
  OPTIONS,
  ADDCOMMENTSBUTTON,
  AddCommentButton,
  CONTENT,
  REPORTMODAL,
} from "constants/index";
import SendCompanyNames from "page/DomainAnalysis/components/SendCompanyNames";
import { StackBarChart } from "components";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import { ProgressBar } from "./components/Form/ProgressBar";
import {
  InfoCardsContainer,
  CustomTable,
  GroupedByQuarter,
  MissingMarksTable,
  MissingProductTermsInCountry,
  MissingProductTermsInTrademarks,
  Form,
  UniqueOwners,
  StatusDistribution,
  Top5Classes,
} from "./components";
import { useDownloadReport } from "hooks/useDownloadReport";
import Button from "components/Button";
import MostVulnerableNonUseTrademarks from "./components/ScrollableTable";
import CheckboxGroup from "components/Checkbox/checkbox";
import CustomButton from "components/Button/CustomButton";
import ModalEdit from "./components/cskEditor/another";
import LogoForm from "./components/Form/LogoForm";
import { generateAndDownloadPDF } from "hooks/useGenerateReport";
import { toast } from "react-toastify";

function Dashboard() {
  const {
    processFile,
    tableRecords,
    showResults,
    uploadPercentage,
    value,
    uniqueOwner,
    setUploadPercentage,
    countryDistribution,
    show,
    uniqueTotal,
    unusedTrademarks,
    unusedTrademarksCount,
    processing,
    filename
  } = useTrademarkAnalysisData();
  const [selectedOption, setSelectedOption] = useState<
    { value: string; label: string } | [] | any
  >([]);
  const { downloadReport } = useDownloadReport();
  const [showUnusedTrademarks, setShowUnusedTrademarks] = useState(false)
  const [showUniqueOwners, setShowUniqueOwners] = useState(false);
  const [addCommentsButton, setAddCommentsButton] =
    useState<AddCommentButton>(ADDCOMMENTSBUTTON);
  const [modals, setModals] = useState(REPORTMODAL);
  const handleModalToggle = (modalKey: string, isOpen: boolean) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalKey]: isOpen,
    }));
  };
  const convertHtmlToText = (htmlContent: any) => {
    return htmlContent.replace(/\n/g, " ");
  };
  const [state, setState] = useState({
    editorContent: CONTENT
  });
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const reportData = {
    countryDistribution: addCommentsButton.hasCountryDistribution ? "Country Distribution" : "",
    countryDistributionText: convertHtmlToText(state.editorContent.editorContent),
    introduction: convertHtmlToText(state.editorContent.editorContentIntro),
    expiringSoonMarks: addCommentsButton.hasExpiringSoonTrademarksbyQuater ? "Expiring Soon Trademarks by Quater" : "",
    expiringSoonMarksText: convertHtmlToText(state.editorContent.expiringSoonTrademarksbyQuater),
    missingCoverage: addCommentsButton.hasMissingCoverage ? "Missing Coverage" : "",
    missingCoverageText: convertHtmlToText(state.editorContent.editorContentMissingCoverage),
    totalCountryRepresentatives: addCommentsButton.hasCountryRepresentatives ? "Country Representatives" : "",
    totalCountryRepresentativesText: convertHtmlToText(state.editorContent.countryRepresentativeText),
    mostFrequentMark: addCommentsButton.hasMostFrequentMarks ? "Most Frequent Marks" : "",
    mostFrequentMarkText: convertHtmlToText(state.editorContent.mostFrequentMarksText),
    topCountriesMissingMostMarks: addCommentsButton.hasTopCountriesMissingMostTopMark ? "Top Countries Missing Most Top Mark" : '',
    topCountriesMissingMostMarksText: convertHtmlToText(state.editorContent.topCountriesMissingMostTopMarkText),
    statusDistribution: addCommentsButton.hasStatusDistribution ? "Status Distribution" : "",
    statusDistributionText: convertHtmlToText(state.editorContent.statusDistributionText),
    mostVulnerableNonUseTradeMarks: addCommentsButton.hasMostVulnerableNonUseTrademarks ? "Most Vulnerable Non-Use Trademarks" : "",
    mostVulnerableNonUseTradeMarksText: convertHtmlToText(state.editorContent.editorContentVulnerable),
    missingProductTermsInCountry: addCommentsButton.hasMissingProductTermInCountry ? "Missing Product Term In Country" : "",
    missingProductTermsInCountryText: convertHtmlToText(state.editorContent.missingProductTermInCountryText),
    missingProductTermsInTradeMarks: addCommentsButton.hasMissingProductTermInTradeMark ? "Missing Product Term In TradeMark" : "",
    missingProductTermsInTradeMarksText: convertHtmlToText(state.editorContent.missingProductTermInTrademarkText),
    tradeMarkMostMissingClasses: addCommentsButton.hasTradeMarkMostMissingClasses ? "TradeMark Most Missing Classes" : "",
    tradeMarkMostMissingClassesText: convertHtmlToText(state.editorContent.topCountriesMissingMostTopMarkText),
    top5Classes: addCommentsButton.hasTop5Class ? "Top 5 Classes" : "",
    top5ClassesText: convertHtmlToText(state.editorContent.editorContentTop5Classes),
    logo: logoFile,
  }
  const downloadReportData = async () => {
    setDownloadReportStatus(true);
    try {
      await downloadReport(filename);
    } catch (error) {
    } finally {
      setDownloadReportStatus(false);
    }
  };
  const handleEditorContentChange = (key: any, content: any) => {
    setState((prevState) => ({
      ...prevState,
      editorContent: {
        ...prevState.editorContent,
        [key]: content,
      },
    }));
  };
  const [downloadReportStatus, setDownloadReportStatus] = useState(false);
  const [downloadPdfStatus, setDownloadPdfStatus] = useState(false);

  useEffect(() => {
    selectedOption.forEach((option: { value: string; label: string }) => {
      switch (option.value) {
        case "Country Distribution":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasCountryDistribution: true,
          }));
          break;
        case "Missing Coverage":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasMissingCoverage: true,
          }));
          break;
        case "Most Vulnerable Non-Use Trademarks":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasMostVulnerableNonUseTrademarks: true,
          }));
          break;
        case "Top 5 Class":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasTop5Class: true,
          }));
          break;
        case "Expiring Soon Trademarks by Quarter":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasExpiringSoonTrademarksbyQuater: true,
          }));
          break;
        case "Status Distribution":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasStatusDistribution: true,
          }));
          break;
        case "Missing Product Term In Country":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasMissingProductTermInCountry: true,
          }));
          break;
        case "Missing Product Term In TradeMark":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasMissingProductTermInTradeMark: true,
          }));
          break;
        case "TradeMark Most Missing Classes":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasTradeMarkMostMissingClasses: true,
          }));
          break;
        case "Top Countries Missing Most Top Mark":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasTopCountriesMissingMostTopMark: true,
          }));
          break;
        case "Country Representatives":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasCountryRepresentatives: true,
          }));
          break;
        case "Most Frequent Marks ":
          setAddCommentsButton((prevState: any) => ({
            ...prevState,
            hasMostFrequentMarks: true,
          }));
          break;
        default:
          break;
      }
    });
  }, [selectedOption]);
  const countryRepresentationData = useMemo(
    () =>
      tableRecords?.country_representation?.map(
        ({ Country, Representative }, index) => {
          return {
            col1: Country,
            col2: Representative,
          };
        }
      ),
    [tableRecords?.country_representation]
  );
  const mostFrequentMarksData = useMemo(
    () =>
      tableRecords?.most_frequent_marks?.map((item: any, index) => {
        return {
          col1: item["Trade Mark"],
          col2: item['Country Coverage'],
          logoSection: item["Image Path"]
        };
      }),
    [tableRecords?.most_frequent_marks]
  );
  const tradeMarkMostMissingCountry = useMemo(
    () =>
      tableRecords?.top_missing_trademark_countries?.map((item: any, index) => {
        return {
          col1: item["Top 10 Countries"],
          col2: item["trademarks count"],
          col3: item["Trademarks Not Present In"]?.map((item: any) => item.Trademark).join(", "),
          logoSection: item["Trademarks Not Present In"]?.map((item: any) => item['Image Path'])
        };
      }),
    [tableRecords?.top_missing_trademark_countries]
  );
  const tradeMarkMostMissingClasses = useMemo(
    () =>
      tableRecords?.trademark_most_missing_classes?.map((item: any, index) => {
        return {
          col1: item["Absence Count"],
          col2: item["Trademark"],
          logoSection: item["Image Path"]
        };
      }),
    [tableRecords?.trademark_most_missing_classes]
  );
  const handleFileUpload = (file: File) => {
    setLogoFile(file);
  };
  const handleCapturePDF = async () => {
    handleModalToggle('modal1', false);
    const hasAnyAddCommentsButton = Object.values(addCommentsButton).some(value => value === true);
    if (reportData.logo && hasAnyAddCommentsButton) {
      setDownloadPdfStatus(true);
      try {
        await generateAndDownloadPDF(reportData, filename);
      }
      catch (error) {
      } finally {
        setDownloadPdfStatus(false);
      }
    }
    else {
      toast.error("Select the logo and select any one option.");
    }
  };
  return (
    <WrapperLayout>
      <div className="flex-col gap-x-4 ">
        <Form
          processFile={processFile}
        />
        {/* {!showResults && (
          <div className="flex justify-center items-center mt-6">
            {uploadPercentage > 0 && <ProgressBar percentage={uploadPercentage} value={value} />}
          </div>
        )} */}
        {!showResults && processing && <Loader />}
        {showResults && (
          <>
            <div className="px-0 sm:px-4">
              <h2 className="font-bold text-xl mt-4 mb-3 justify-center items-center">
                Select Content For Report
              </h2>
              <CheckboxGroup
                options={OPTIONS}
                selectedOptions={selectedOption}
                setSelectedOptions={setSelectedOption}
              />
            </div>

            <div id="capture-container">
              {uniqueOwner && uniqueOwner.length ?
                <h3 className="text-3xl px-4 sm:mt-8 mt-[140px] text-center text-black font-bold">
                  Trademark Analysis (
                  {uniqueOwner[0]["Unique Owner Name"]})
                </h3>
                : null}

              <div className="my-8 grid grid-cols-1 md:grid-cols-2 gap-4 px-4">
                <InfoCardsContainer
                  heading1="Total Country Representatives"
                  value1={
                    tableRecords?.country_representation.reduce(
                      (n, { Representative }) => n + Representative,
                      0
                    ) || 0
                  }
                  handleClickAction={() => setShowUniqueOwners(true)}
                  heading2="Total Unique Consistent Owners"
                  value2={
                    uniqueTotal || 0
                  }
                />
              </div>

              <div className="flex flex-wrap mt-8 ">
                <div className="w-full xl:overflow-auto  px-4  ">
                  <StackBarChart
                    Data={countryDistribution}
                    axis="x"
                  />
                </div>
                {addCommentsButton.hasCountryDistribution && (
                  <div className="w-max  px-4 mt-1">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal2", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange("editorContent", content)
                      }
                      isOpen={modals.modal2}
                      onRequestClose={() => handleModalToggle("modal2", false)}
                    />
                  </div>
                )}
                <div className="w-full xl:overflow-auto px-4">
                  <GroupedByQuarter />
                  {addCommentsButton.hasExpiringSoonTrademarksbyQuater && (
                    <div className="w-max mt-1">
                      <CustomButton
                        title="Add Comments"
                        onClick={() => handleModalToggle("modal13", true)}
                      />
                      <ModalEdit
                        setEditorContent={(content: string) =>
                          handleEditorContentChange(
                            "expiringSoonTrademarksbyQuater",
                            content
                          )
                        }
                        isOpen={modals.modal13}
                        onRequestClose={() =>
                          handleModalToggle("modal13", false)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div className="my-8 flex flex-wrap mt-8 ">
              <div className="w-full lg:w-1/2 xl:w-1/2 px-4">
                <CustomTable
                  heading={`Country Representatives: ${tableRecords?.country_representation.filter((item)=>item.Country !== 'All Countries').reduce(
                    (n, { Representative }) =>  n + Representative,
                    0
                  ) || 0
                    }`}
                  data={countryRepresentationData || []}
                  columns={COUNTRYREPRESENTATION}
                  clickable={true}
                  countryTable={true}
                  name={"result_total_country_representatives"}
                />
                {addCommentsButton.hasCountryRepresentatives && (
                  <div className="w-[200px] mt-1">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal3", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "countryRepresentativeText",
                          content
                        )
                      }
                      isOpen={modals.modal3}
                      onRequestClose={() => handleModalToggle("modal3", false)}
                    />
                  </div>
                )}
              </div>
              <div className="w-full lg:w-1/2 xl:w-1/2 px-4">
                <CustomTable
                  heading={`Most Frequent Marks : ${tableRecords?.most_frequent_marks.reduce(
                    (n: number, data: any) =>
                      n + data['Country Coverage'],
                    0
                  ) || 0
                    }`}
                  data={mostFrequentMarksData || []}
                  columns={MOSTFREQUENTMARKS}
                  clickable={true}
                  name={"result_most_frequent_mark"}
                />
                {addCommentsButton.hasMostFrequentMarks && (
                  <div className="w-[200px] mt-1">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal4", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "countryRepresentativeText",
                          content
                        )
                      }
                      isOpen={modals.modal4}
                      onRequestClose={() => handleModalToggle("modal4", false)}
                    />
                  </div>
                )}
              </div>

              <div></div>
            </div>
            <div>
              <MissingMarksTable filename={filename}/>
              {addCommentsButton.hasMissingCoverage && (
                <div className="w-[200px] px-3 mt-1">
                  <CustomButton
                    title="Add Comments"
                    onClick={() => handleModalToggle("modal5", true)}
                  />
                  <ModalEdit
                    setEditorContent={(content: string) =>
                      handleEditorContentChange(
                        "editorContentMissingCoverage",
                        content
                      )
                    }
                    isOpen={modals.modal5}
                    onRequestClose={() => handleModalToggle("modal5", false)}
                  />
                </div>
              )}
            </div>
            <div>
              <div className="mt-8  flex-col flex-wrap  px-4">
                <CustomTable
                  heading={"Top 10 Countries Missing Top 10 Trade Marks"}
                  data={tradeMarkMostMissingCountry || []}
                  columns={MOSTMISSINGTRADEMARKSCOUNTRIES}
                  name={'result_top_countries_missing_marks'}
                />
              </div>
              {addCommentsButton.hasTradeMarkMostMissingClasses && (
                <div className="w-[200px] px-4">
                  <CustomButton
                    title="Add Comments"
                    onClick={() => handleModalToggle("modal6", true)}
                  />
                  <ModalEdit
                    setEditorContent={(content: string) =>
                      handleEditorContentChange(
                        "tradeMarkMostMissingClassesText",
                        content
                      )
                    }
                    isOpen={modals.modal6}
                    onRequestClose={() => handleModalToggle("modal6", false)}
                  />
                </div>
              )}
            </div>
            <div className="my-8 flex flex-wrap mt-8 ">
              <div className="w-full lg:w-1/2 xl:overflow-auto px-4">
                <StatusDistribution
                  dataset={tableRecords?.status_distribution}
                />
                {addCommentsButton.hasStatusDistribution && (
                  <div className="w-[150px]">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal7", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "statusDistributionText",
                          content
                        )
                      }
                      isOpen={modals.modal7}
                      onRequestClose={() => handleModalToggle("modal7", false)}
                    />
                  </div>
                )}
              </div>

              <div className="w-full lg:w-1/2 xl:overflow-auto px-4">
                <h2 className="mb-3 mt-4">
                  <span className="text-2xl text-black font-bold">
                    Trade Marks Vulnerable to Cancellation for Non-Use ({unusedTrademarksCount})
                  </span> <span className="text-sm ml-2 text-green hover:underline cursor-pointer" onClick={() => setShowUnusedTrademarks(true)}>View All</span>
                </h2>
                <div>
                  <MostVulnerableNonUseTrademarks data={unusedTrademarks} showUnusedTrademarks={showUnusedTrademarks} setShowUnusedTrademarks={setShowUnusedTrademarks} />
                </div>

                {addCommentsButton.hasMostVulnerableNonUseTrademarks && (
                  <div className="w-[150px]">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal8", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "editorContentVulnerable",
                          content
                        )
                      }
                      isOpen={modals.modal8}
                      onRequestClose={() => handleModalToggle("modal8", false)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="my-8 flex flex-wrap mt-8 ">
              <div className="w-full lg:w-1/2 xl:overflow-auto px-4">
                <div>{<MissingProductTermsInTrademarks />}</div>
                {addCommentsButton.hasMissingProductTermInTradeMark && (
                  <div className="w-[200px]">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal9", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "missingProductTermInTrademarkText",
                          content
                        )
                      }
                      isOpen={modals.modal9}
                      onRequestClose={() => handleModalToggle("modal9", false)}
                    />
                  </div>
                )}
              </div>
              <div className="w-full lg:w-1/2 xl:overflow-auto px-4">
                <div>{<MissingProductTermsInCountry />}</div>
                {addCommentsButton.hasMissingProductTermInCountry && (
                  <div className="w-[200px]">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal10", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "missingProductTermInCountryText",
                          content
                        )
                      }
                      isOpen={modals.modal10}
                      onRequestClose={() => handleModalToggle("modal10", false)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 px-4 mb-4">
                <CustomTable
                  heading="Trade Marks Missing the Most Classes"
                  data={tradeMarkMostMissingClasses || []}
                  columns={MOSTMISSINGCLASSES}
                  name={"result_classes_misses_trademarks"}
                />
              </div>
              {addCommentsButton.hasTradeMarkMostMissingClasses && (
                <div className="w-[200px] mx-4">
                  <CustomButton
                    title="Add Comments"
                    onClick={() => handleModalToggle("modal11", true)}
                  />
                  <ModalEdit
                    setEditorContent={(content: string) =>
                      handleEditorContentChange(
                        "topCountriesMissingMostTopMarkText",
                        content
                      )
                    }
                    isOpen={modals.modal11}
                    onRequestClose={() => handleModalToggle("modal11", false)}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col flex-wrap my-8 ">
              <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 px-4 mb-4">
                <Top5Classes dataset={tableRecords?.top_5_classes} />
              </div>
              <div>
                {addCommentsButton.hasTop5Class && (
                  <div className="w-[200px] px-4">
                    <CustomButton
                      title="Add Comments"
                      onClick={() => handleModalToggle("modal12", true)}
                    />
                    <ModalEdit
                      setEditorContent={(content: string) =>
                        handleEditorContentChange(
                          "editorContentTop5Classes",
                          content
                        )
                      }
                      isOpen={modals.modal12}
                      onRequestClose={() => handleModalToggle("modal12", false)}
                    />
                  </div>
                )}
              </div>
            </div>
            {showUniqueOwners ? (
              <UniqueOwners
                showTable={showUniqueOwners}
                setShowTable={setShowUniqueOwners}
              />
            ) : (
              false
            )}


            <div className="w-full  mx-auto gap-x-4 flex justify-center items-center flex-wrap h-full gap-y-5 ">
              <Button
                title={`${downloadReportStatus ? "Downloading..." : "Download All Data"
                  }`}
                isLoading={downloadReportStatus}
                onClick={() => downloadReportData()}
                className="max-w-[300px] w-full"
              />
              <LogoForm onFileUpload={handleFileUpload} />
              <Button
                title={`${downloadPdfStatus ? "Downloading..." : "Download Report"
                  }`}
                isLoading={downloadPdfStatus}
                onClick={() => handleModalToggle("modal1", true)}
                className="max-w-[300px] w-full"
              />

              <ModalEdit
                setEditorContent={(content: string) =>
                  handleEditorContentChange("editorContentIntro", content)
                }
                isOpen={modals.modal1}
                onRequestClose={handleCapturePDF}
              />
            </div>
            <SendCompanyNames customClass={"px-4"}/>
          </>
        )}
      </div>

    </WrapperLayout>
  );
}

export default Dashboard;

